import React from 'react';
import './dashnav.css';
import axios from 'axios';
import IdleTimer from "./IdleTimer";

class Dashnav extends React.Component {
  constructor() {
    super();
    this.logout = this.logout.bind(this);
    this.inactivityTime = this.inactivityTime.bind(this);
    this.state = {
      logout: false,
      username: '',
      isTimeout: false,
      setIsTimeout: false,
      memberof: '',
    }
  }

  componentDidMount(){
    this.inactivityTime();
    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  logout = (e) => {

    e.preventDefault();

  var date = new Date();
  var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
  var timeInput = date.toLocaleTimeString();

  var access_token = this.getCookie('access_token');
    console.log("ACCESS TOKEN: " + access_token);

    var bodyFormData = new FormData();
    bodyFormData.append('username', this.getCookie('username'));
    bodyFormData.append('page', "dashboard");
    bodyFormData.append('action', "logout");
    bodyFormData.append('date', dateInput);
    bodyFormData.append('time', timeInput);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/access/insert.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);

        axios({
          method: 'post',
          url: 'http://174.32.189.27:5000/Logout',
          headers: { 
            'Authorization': 'Bearer ' + access_token
          }
        })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          console.log("ALL LOGGED OUT");

          document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "fullname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "memberof=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "remember=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          window.location.reload(false);

        })
        .catch(function (error) {
          console.log(error);

          document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "fullname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "memberof=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "remember=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        window.location.reload(false);
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });


  }

inactivityTime = () => {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function getCookie (cname){
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function logout() {
      console.log("You are now logged out.");

      var access_token = getCookie('access_token');
      console.log("ACCESS TOKEN: " + access_token);

      axios({
        method: 'post',
        url: 'http://174.32.189.27:5000/Logout',
        headers: { 
          'Authorization': 'Bearer ' + access_token
        }
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

      document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "fullname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "memberof=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "remember=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    }
    function resetTimer() {
      console.log(getCookie("remember"));
      clearTimeout(time);
      var remTime = 0;
      if(getCookie('remember') === 'true'){
        remTime = 1000 * 1 * 60 * 60 * 10;
      }
      else{
        remTime = 1000 * 1 * 60 * 60 * 8;
      }
      time = setTimeout(logout, remTime);
    }
  };

  render() {
    return(
        <div className="fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
          <a href="/" className="navbar-brand"><img className="production-logo" src="./img/case-med-logo.png" style={{height: '40px'}} /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarColor02">
            <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" href="/dashboard">Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Boards</a>
              <div className="dropdown-menu">
                {this.state.memberof.includes('QC') || this.state.memberof.includes('Administrator') ?
                <div>
                  <a className="dropdown-item" href="/qc">QC</a> 
                  <a className="dropdown-item" href="/finalqc">Final QC</a>
                </div>
                : '' }
                {this.state.memberof.includes('QC') || this.state.memberof.includes('Administrator') || this.state.memberof.includes('Engineering') ?
                <div>
                  <a className="dropdown-item" href="/kits">Kits</a>
                </div>
                : '' }
                {this.state.memberof.includes('QC') || this.state.memberof.includes('Administrator') || this.state.memberof.includes('Engineering') || this.state.memberof.includes('Production') ?
                <div>
                  <a className="dropdown-item" href="/silicone">Silicone</a>
                  <a className="dropdown-item" href="/boards">Assembly</a>
                  <a className="dropdown-item" href="/finas">Final Assembly</a>
                  <a className="dropdown-item" href="/debur">Deburring</a>
                  <a className="dropdown-item" href="/punch">Punching</a>
                  <a className="dropdown-item" href="/hydro">Hydros</a>
                  <a className="dropdown-item" href="/anodize">Anodizing</a>
                  <a className="dropdown-item" href="/silk">Silk Screening</a>
                  <a className="dropdown-item" href="/spotwelding">Spot Welding</a>
                  <a className="dropdown-item" href="/timesaver">Timesaver</a>
                  <a className="dropdown-item" href="/whiteboard">Whiteboard</a>
                </div>
                : '' }
              </div>
            </li>
            {this.state.memberof.includes('QC') || this.state.memberof.includes('Administrator') || this.state.memberof.includes('Engineering') || this.state.memberof.includes('Production') ?
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Production</a>
              <div className="dropdown-menu">
                <div>
                  <a className="dropdown-item" href="/drawings">Engineering Drawings</a>
                  <a className="dropdown-item" href="/partlots">Part Lots</a>
                  <a className="dropdown-item" href="/labels">Labels</a>
                  <a className="dropdown-item" href="/setupsheets">Setup Sheets</a>
                  <a className="dropdown-item" href="/picklist">Sales Order Picklist</a>
                  <a className="dropdown-item" href="/cofc">Certificate(s) of Compliance</a>
                </div>
              </div>
            </li>
            : '' }
            {this.state.memberof.includes('Anodizing') || this.state.memberof.includes('QC') || this.state.memberof.includes('Engineering') || this.state.memberof.includes('Administrator') ?
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Anodizing</a>
              <div className="dropdown-menu">
              <a className="dropdown-item" href="/anodizelog">Log</a>
              <a className="dropdown-item" href="/anodizeparts">Parts</a>
                <a className="dropdown-item" href="/anodizing">Anodizing Sheet</a>
                <a className="dropdown-item" href="/passivation">Passivation Sheet</a>
                {/*<a className="dropdown-item" href="/anodizelog">Logs</a>
                <a className="dropdown-item" href="/anodizeparts">Parts</a>*/}
              </div>
            </li>
            : '' }
            {this.state.memberof.includes('Accounting') || this.state.memberof.includes('Administrator') ?
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Export(s)</a>
              <div className="dropdown-menu">
                <div>
                  <a className="dropdown-item" href="/salesorders">Sales Orders</a>
                  <a className="dropdown-item" href="/purchase">Purchased Products</a>
                  <a className="dropdown-item" href="/quotes">Quotes</a>
                  <a className="dropdown-item" href="/quickbooks">QuickBooks</a>
                  <div class="dropdown-divider"></div>
                  {/*<a className="dropdown-item" href="/eyepro">Eyepro Admin Fee(s)</a>
                  <a className="dropdown-item" href="/hpg">HPG Admin Fee(s)</a>
                  <a className="dropdown-item" href="/novation">Novation Admin Fee(s)</a>
                  <a className="dropdown-item" href="/premier">Premier Admin Fee(s)</a>
                  <a className="dropdown-item" href="/va">VA Admin Fee(s)</a>
                  <a className="dropdown-item" href="/vizient">Vizient Admin Fee(s)</a>*/}
                  <a className="dropdown-item" href="/generaladmin">General Admin Fee(s)</a>
                </div>
              </div>
            </li>
            : '' }
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Shipping Reports</a>
              <div className="dropdown-menu" >
                <div>
                  <a className="dropdown-item" href="http://54.211.224.243/api/odoo/reports/shippingpriority/static"> Static Report(s)</a>
                  <a className="dropdown-item" href="/shippingpriorityall">Shipping Priority All</a>
                  <a className="dropdown-item" href="/shippingpriorityboss">Shipping Priority Boss</a>
                  <a className="dropdown-item" href="/shippingprioritycomplete">Shipping Priority Complete</a>
                  <a className="dropdown-item" href="/shippingprioritycontainers">Shipping Priority Containers</a>
                  <a className="dropdown-item" href="/shippingprioritydelivery">Shipping Priority Delivery</a>
                  <a className="dropdown-item" href="/shippingprioritydepuy">Shipping Priority DePuy</a>
                  <a className="dropdown-item" href="/shippingprioritydepuymedtronic">Shipping Priority DePuy + Medtronic</a>
                  <a className="dropdown-item" href="/shippingprioritykarlstorz">Shipping Priority KarlStorz</a>
                  <a className="dropdown-item" href="/shippingprioritykarlstorzdone">Shipping Priority KarlStorz (Done)</a>
                  <a className="dropdown-item" href="/shippingprioritymedtronic">Shipping Priority Medtronic</a>
                  <a className="dropdown-item" href="/shippingprioritymedtronicdone">Shipping Priority Medtronic (Done)</a>
                  <a className="dropdown-item" href="/shippingprioritynonoem">Shipping Priority Healthcare Facilities</a>
                  <a className="dropdown-item" href="/shippingprioritynsi">Shipping Priority NSI</a>
                  <a className="dropdown-item" href="/shippingpriorityoem">Shipping Priority OEM</a>
                  <a className="dropdown-item" href="/shippingpriorityolympus">Shipping Priority Olympus</a>
                  <a className="dropdown-item" href="/shippingpriorityonhand">Shipping Priority On-Hand</a>
                  <a className="dropdown-item" href="/shippingprioritysolutions">Shipping Priority Solutions</a>
                  <a className="dropdown-item" href="/shippingpriorityteleflex">Shipping Priority Teleflex</a>
                  <a className="dropdown-item" href="/shippingpriorityynumbers">Shipping Priority Y Numbers</a>
                  <a className="dropdown-item" href="/location">Shipping Priority Locations</a>
                  <a className="dropdown-item" href="/receiving">Shipping Priority Receiving</a>
                  {this.state.memberof.includes('Administrator') ||  this.state.memberof.includes('QC')?
                  <div>
                    <a className="dropdown-item" href="/dailyinventory">Daily Inventory Tracker</a>
                    <a className="dropdown-item" href="/inventory">Historical Inventory Tracker</a>
                  </div>
                : ''}
                </div>                  
              </div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Data Analysis Reports</a>
              <div className="dropdown-menu" >
                <div>
                  <a className="dropdown-item" href="/autobuy">Auto Buy Program Overview</a>
                  {/* <a className="dropdown-item" href="/canadasales">Canada Sales Analysis Report</a> */}
                  {/* <a className="dropdown-item" href="/chinasales">China Sales Analysis Report</a> */}
                  {/* <a className="dropdown-item" href="/consumablescontainersales">Consumables-Containers Sales Report</a> */}
                  <a className="dropdown-item" href="/customerpurchase">Customer Purchasing Log</a>
                  <a className="dropdown-item" href="/distributor">Distributor Sales Analysis Report</a>
                  <a className="dropdown-item" href="/distributorinv">Distributor Sales Analysis Report Inv</a>
                  <a className="dropdown-item" href="/domesticsales">Domestic Clinical Sales</a>
                  <a className="dropdown-item" href="/emailanalytics">Email Analytics Log</a>
                  {/* <a className="dropdown-item" href="/ecommerce">ECommerce Sales Analytics</a> */}
                  <a className="dropdown-item" href="/maintenance">Infrastructure Maintenance Log Summary</a>
                  <a className="dropdown-item" href="/inventorydata">Inventory Management Report</a>
                  {/* <a className="dropdown-item" href="/irelandsales">Ireland Sales Report</a> */}
                  {/* <a className="dropdown-item" href="/japansales">Japan Sales Analysis Report</a> */}
                  {/* <a className="dropdown-item" href="/latinsales">Latin America Sales Analysis Report</a> */}
                  <a className="dropdown-item" href="/mfgcomp">Manufacturing Component Usage Analysis</a>
                  <a className="dropdown-item" href="/manufacturing">Manufacturing Report</a>
                  <a className="dropdown-item" href="/mastercross">Master Cross Report</a>
                  <a className="dropdown-item" href="/maxsales">MAX Sales History Report</a>
                  <a className="dropdown-item" href="/negativenumbers">Negative Numbers Report</a>
                  <a className="dropdown-item" href="/novaplussales">Novaplus Pricing - Sales Report</a>
                  <a className="dropdown-item" href="/orderleadtime">Order Lead Time Report</a>
                  <a className="dropdown-item" href="/sales2024">Pipeline Sales Analysis 2024</a>
                  <a className="dropdown-item" href="/safetystock">Safety Stock Report</a>
                  <a className="dropdown-item" href="/salesanalytics">Sales Analytics Report</a>
                  <a className="dropdown-item" href="/salesreport">Sales Report</a>
                  {/* <a className="dropdown-item" href="/southkoreasales">South Korea Sales Analytics Report</a> */}
                  <a className="dropdown-item" href="/summaryreport">Summary Report</a>
                  {/* <a className="dropdown-item" href="/taiwansales">Taiwan Sales Report</a> */}
                  <a className="dropdown-item" href="/territorypenetration">Territory Penetration Report</a>
                  {/* <a className="dropdown-item" href="/ussales">US Distributors Sales Analysis Report</a> */}
                  <a className="dropdown-item" href="/webtraffic">Web Traffic Analytics Report</a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/wca">Work Cell Assignments
              </a>
            </li>
            </ul>
              <div style={{marginRight: '50px'}}>
                <form className="form-inline my-2 my-lg-0">
                {this.state.memberof.includes('Administrator') ?
                <a className="nav-link" style={{borderRadius: '100px', padding: '10px', background: '#354a5a'}} target="_blank" href="https://admin:Admin2022!@192.168.200.120:3000/home"><i class="fa fa-microphone" style={{color: 'white'}} aria-hidden="true"></i><span style={{color: 'white'}}> | </span><i class="fa fa-volume-up" style={{color: 'white'}} aria-hidden="true"></i>
              </a> : ''}
                <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                  <button type="button" style={{background: '#354a5a !important'}} class="btn btn-secondary my-2 my-sm-0 dash-btn-nav">{this.getCookie('username')}</button>
                  <div class="btn-group" role="group">
                    <button id="btnGroupDrop1" style={{background: '#354a5a !important'}} type="button" class="btn btn-secondary my-2 my-sm-0 dash-btn-nav dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <a class="dropdown-item" href="/profile">Profile</a>
                      {this.state.memberof.includes('Administrator') ?
                      <div>
                        <a class="dropdown-item" href="http://admin:Admin2022!@viewbox.casemed.com:8080/api/admin/index.php">Admin</a>
                      </div>
                      : '' }
                      {this.state.memberof.includes('Administrator') ?
                      <div>
                        <a class="dropdown-item" href="http://admin:Admin2022!@viewbox.casemed.com:8080/api/admin/access.php">Access Log</a>
                      </div>
                      : '' }
                      <a class="dropdown-item" href="https://54.211.224.243/ITPortal/index.php/help-desk/submit-ticket" target="_blank">Help</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" onClick={this.logout} href="">Logout</a>
                    </div>
                  </div>
                </div>
                </form>
              </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Dashnav;